import React, { Suspense, lazy } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import Preloader from "./components/preloader";
import { useAppSelector } from "./redux/hooks";

const DashboardOne = lazy(() => import("./pages/dashboard-one"));
// const Genealogy = lazy(() => import("./pages/genealogy"));
const Accounts = lazy(() => import("./pages/accounts"));
const Sales = lazy(() => import("./pages/sales"));
const Product = lazy(() => import("./pages/product-sales"));
const SignIn = lazy(() => import("./pages/signin"));
const SignUp = lazy(() => import("./pages/signup"));
const ProfileView = lazy(() => import("./pages/profile-view"));

const AuthRoute = (props: any) => {
    const { type } = props;
    const { auth } = useAppSelector((state) => state.user);

    if (type === "guest" && auth) return <Redirect to="/" />;
    if (type === "private" && !auth) return <Redirect to="/sign-in" />;

    return <Route {...props} />;
};

const App: React.FC = () => {
    console.log("App");
    return (
        <>
            <Router>
                <Suspense fallback={<Preloader />}>
                    <Switch>
                        <AuthRoute
                            path="/sign-in"
                            component={SignIn}
                            type="guest"
                        />
                        <AuthRoute
                            path="/sign-up/:username"
                            component={SignUp}
                            type="guest"
                        />
                        <AuthRoute
                            path="/sign-up"
                            component={SignUp}
                            type="guest"
                        />
                        {/* <AuthRoute
                            path="/genealogy"
                            component={Genealogy}
                            type="private"
                        /> */}
                        <AuthRoute
                            path="/accounts"
                            component={Accounts}
                            type="private"
                        />
                        <AuthRoute
                            path="/sales"
                            component={Sales}
                            type="private"
                        />
                        <AuthRoute
                            path="/profile-view"
                            component={ProfileView}
                            type="private"
                        />
                        <AuthRoute
                            path="/product"
                            component={Product}
                            type="private"
                        />
                        <AuthRoute
                            path="/"
                            component={DashboardOne}
                            type="private"
                        />
                    </Switch>
                </Suspense>
            </Router>
        </>
    );
};

export default App;
