import { configureStore, combineReducers } from "@reduxjs/toolkit";
import eventReducer from "./slices/event";
import uiReducer from "./slices/ui";
import chatUISlice from "./slices/chat-ui";
import userReducer from "./slices/user";
import dashboardReducer from "./slices/dashboard";
import genealogyReducer from "./slices/genealogy";
import accountsReducer from "./slices/accounts";
import commissionReducer from "./slices/commission";
import productReducer from "./slices/product";

const rootReducer = combineReducers({
    events: eventReducer,
    ui: uiReducer,
    chatUI: chatUISlice,
    user: userReducer,
    dashboard: dashboardReducer,
    genealogy: genealogyReducer,
    accounts: accountsReducer,
    commission: commissionReducer,
    products: productReducer,
});

export const store = configureStore({
    reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
