/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AccountsState {
    total_looping_bonus: number;
    total_accounts: number;
    total_paid_accounts: number;
    total_cd_accounts: number;
    total_matured_accounts: number;
    accounts: any;
    activationPin: any[] | null;
    availableBalance: number;
}

const initialState: AccountsState = {
    total_looping_bonus: 0,
    total_accounts: 0,
    total_paid_accounts: 0,
    total_cd_accounts: 0,
    total_matured_accounts: 0,
    accounts: null,
    activationPin: null,
    availableBalance: 0,
};

const accountsSlice = createSlice({
    name: "accounts",
    initialState,
    reducers: {
        saveAccounts(
            state,
            action: PayloadAction<{
                data: {
                    total_looping_bonus: number;
                    total_accounts: number;
                    total_paid_accounts: number;
                    total_cd_accounts: number;
                    total_matured_accounts: number;
                    accounts: any;
                    activation_pin: any[] | null;
                    available_balance: number;
                };
            }>
        ) {
            const {
                payload: { data },
            } = action;
            state.total_looping_bonus = data.total_looping_bonus;
            state.total_accounts = data.total_accounts;
            state.total_paid_accounts = data.total_paid_accounts;
            state.total_cd_accounts = data.total_cd_accounts;
            state.total_matured_accounts = data.total_matured_accounts;
            state.activationPin = data.activation_pin;
            state.accounts = data.accounts;
            state.availableBalance = data.available_balance;
        },
        saveFetchAccounts(
            state,
            action: PayloadAction<{
                accounts: any;
            }>
        ) {
            const { payload } = action;
            state.accounts = payload.accounts;
        },
    },
});

export const { saveAccounts, saveFetchAccounts } = accountsSlice.actions;
export default accountsSlice.reducer;
