/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUserState {
    auth: boolean;
    user: any;
    account: any;
    token: any;
}

const initialState: IUserState = {
    auth: !!localStorage.getItem("ez-key"),
    user: localStorage.getItem("ez-user")
        ? JSON.parse(localStorage.getItem("ez-user") || "")
        : null,
    account: localStorage.getItem("ez-account")
        ? JSON.parse(localStorage.getItem("ez-account") || "")
        : null,
    token: localStorage.getItem("ez-key")
        ? JSON.parse(localStorage.getItem("ez-key") || "")
        : null,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        saveUser(
            state,
            action: PayloadAction<{
                user: any;
                token: string;
                account: any;
            }>
        ) {
            const {
                payload: { user, token, account },
            } = action;
            localStorage.setItem("ez-key", JSON.stringify(token));
            localStorage.setItem("ez-user", JSON.stringify(user));
            localStorage.setItem("ez-account", JSON.stringify(account));
            state.auth = true;
            state.user = user;
            state.account = account;
            state.token = token;
        },
        // updateToken(
        //     state,
        //     action: PayloadAction<{
        //         user: any;
        //         token: string;
        //         account: any;
        //     }>
        // ) {
        //     const {
        //         payload: { user, token, account },
        //     } = action;
        //     localStorage.setItem("ez-key", JSON.stringify(token));
        //     state.auth = true;
        //     state.user = user;
        //     state.account = account;
        //     state.user = user;
        // },
        newIncentivesSet(
            state,
            action: PayloadAction<{
                user: any;
            }>
        ) {
            const {
                payload: { user },
            } = action;
            localStorage.removeItem("ez-user");
            localStorage.setItem("ez-user", JSON.stringify(user));
            state.user = user;
        },
        updateAccount(
            state,
            action: PayloadAction<{
                account: any;
            }>
        ) {
            const {
                payload: { account },
            } = action;
            localStorage.removeItem("ez-account");
            localStorage.setItem("ez-account", JSON.stringify(account));
            state.account = account;
        },
        logOut(state) {
            localStorage.removeItem("ez-key");
            localStorage.removeItem("ez-user");
            localStorage.removeItem("ez-account");
            state.auth = false;
            state.user = null;
            state.account = null;
            state.token = null;
        },
    },
});

export const { saveUser, newIncentivesSet, logOut, updateAccount } =
    userSlice.actions;
export default userSlice.reducer;
